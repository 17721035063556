:root {
  --primary-100: #1e2e85;
  --text-100: #1d1c1c;
  --text-200: #313d44;
  --bg-100: #fffefb;
  --bg-200: #f5f4f1;
  --bg-300: #cccbc8;
}

body {
  color: var(--text-100);
  background-color: var(--bg-100);
  width: 100%;
  height: 100%;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin-block-end: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html {
  font-size: 100%;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.4;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: ;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

h1 {
  line-height: 1.1;
  font-size: 1.802rem;
}

h2 {
  font-size: 1.602rem;
}

h3 {
  font-size: 1.424rem;
}

h4 {
  font-size: 1.266rem;
}

h5 {
  font-size: 1.125rem;
}

small {
  font-size: 0.889rem;
}
